<style lang="scss">

  .unit-index {
    .cover {
      min-height: 100vh;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 0 60px;

      @include tablet {
        min-height: 400px;
        padding: 0 40px;
      }

      .content {
        max-width: 1200px;
        padding-top: 150px;
        overflow: hidden;

        .title {
          font-size: 72px;
          line-height: 82px;
          font-weight: 500;
          margin-top: 30px;
          letter-spacing: 4px;

          @include tablet {
            font-size: 52px;
          }

        }
      }
    }

    .container {
      .resume {
        display: flex;
        justify-content: space-between;
        gap: 100px;
        margin-bottom: 100px;

        @include tablet {
          flex-direction: column;
          gap: 50px;
          margin-bottom: 50px;
        }

        .companyData .desc {
          margin-top: 25px;
          margin-bottom: 30px;
          display: block;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;

          @include tablet {
            margin-top: 15px;
            margin-bottom: 20px;
          }
        }

        .importantInfos {
          .title-index {
            margin-bottom: 30px;

            .content {
              font-size: 20px;
              font-weight: bold;

              &::before {
                height: 3px;
              }
            }
          }
          ul li {
            margin-bottom: 30px;
          }
        }
      }
      .timeline {
        margin-bottom: 100px;

        @include tablet {
          margin-bottom: 50px;
        }

        a {
          color: #FBD784!important;
        }

        .title-index .content-full {
          display: flex;
          justify-content: space-between;
          &::before,
          &::after {
            margin: 0;
          }
        }
        ul li {
          margin-top: 25px;
          margin-bottom: 40px;
          list-style: none;
        }
      }
      .mission-vision-values {
        .title-index {
          margin-top: 30px;
          .content-full {
            display: flex;
            justify-content: space-between;
            &::before,
            &::after {
              margin: 0;
            }
          }
          .content {
            margin-top: 25px;
            margin-bottom: 30px;
          }
        }
        p,
        ul li{
          margin: 20px 0 35px;
          list-style: none;
        }
      }
    }
  }

</style>

<template lang="pug">

  .unit-index(v-if="unit")
    .cover(:style="backgroundImage")
      .content
        Title Explore nossa história

        h1.title {{ unit.nome }}

    .container
      .resume
        .companyData
          Title Ano de Fundação
          span.desc {{ unit.ano_de_fundacao }}
          Title Idade da empresa
          span.desc {{ companyAge }}
          Title Nº de clientes
          span.desc {{ unit.clientes }}
          Title Tipo de serviço

          VueMarkdown {{  unit.tipo_de_servicos }}

        .importantInfos
          Title Informações relevantes

          VueMarkdown {{ unit.informacoes_relevantes }}

      .timeline
        Title(side='full') História da Unidade

        VueMarkdown {{ unit.timeline }}

      .mission-vision-values
        Title(side='full') Missão, visão e valores

        Title(v-if="unit.missao") Missão
        p(v-if="unit.missao") {{ unit.missao }}

        Title(v-if="unit.visao") Visão
        p(v-if="unit.visao") {{ unit.visao }}

        Title(v-if="unit.valores") Valores
        VueMarkdown(v-if="unit.valores") {{ unit.valores }}

</template>

<script>

import VueMarkdown from 'vue-markdown';

export default {
  name: 'Editions',

  data() {
    return {
      unit: null
    };
  },

  components: {
    VueMarkdown
  },

  mounted() {
    this.$api.get(`/unidades/${this.$route.params.id}`)
      .then((response) => {
        this.unit = response.data;
        document.title = `${this.unit.nome} - Explore nossa história`;
      });
  },

  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${process.env.VUE_APP_API}${this.unit.background[0].url})`
      };
    },

    companyAge() {
      return new Date().getFullYear() - this.unit.ano_de_fundacao;
    }
  }
};

</script>
